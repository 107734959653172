<template>
  <v-dialog v-model="dialog" persistent max-width="850" class="scroll">
    <v-card class="px-5 pb-4 scroll">
      <!-- <pre>{{ tests }}</pre> -->
      <v-container fluid>
        <v-row justify="center"
          ><div class="title black--text text-uppercase py-4">
            {{ $t("tests") }}
          </div></v-row
        >
        <v-row class="mx-6 mb-1">
          <v-spacer></v-spacer>
          <v-btn
            small
            :color="'primary'"
            v-bind="attrs"
            v-on="on"
            @click="openSelectTest(0)"
          >
            <v-icon>mdi-send</v-icon>
            {{ $t("associete_test") }}
          </v-btn>
        </v-row>
        <v-row class="mx-3 mt-0 scroll-test">
          <v-col
            cols="12"
            md="4"
            lg="4"
            xl="4"
            v-for="test in tests"
            :key="test.id"
          >
            <test-card
              :test="test"
              @preview="openPreviewDialog"
              @delete="removeTest"
            />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end align-center px-2">
          <v-btn class="mt-4" color="primary" text @click="$emit('close')">{{
            $t("close")
          }}</v-btn>
        </v-row>
      </v-container>
    </v-card>

    <preview-test-dialog
      :dialog="previewDialog"
      :test="test"
      @close="previewDialog = false"
      @fetch="$emit('fetch')"
    />
    <select-associate-test
      :dialog="dialogSelectTest"
      v-if="dialogSelectTest"
      :SelectTestIndex="SelectTestIndex"
      @continue="continueSelectTest"
      @close="closeSelectTest()"
    />
  </v-dialog>
</template>

<script>
import TestCard from "./TestCard.vue";
import PreviewTestDialog from "./ReviewTestDialog.vue";
import SelectAssociateTest from "./SelectAssociateTest.vue";
import { UPDATE_STAGES_MUTATION } from "../graphql/Mutation.resolver";
export default {
  name: "PreviewStageTestList",
  components: { PreviewTestDialog, TestCard, SelectAssociateTest },
  props: {
    dialog: Boolean,
    tests: Array,
    stages: Array,
    vacancyId: String,
  },
  data: () => ({
    previewDialog: false,
    test: {},
    SelectTestIndex: -1,
    dialogSelectTest: false,
  }),
  methods: {
    openPreviewDialog(test) {
      this.test = test;
      this.previewDialog = true;
    },

    continueSelectTest(tests, index, newTests) {
      console.log(index, tests);
      if (tests.length) {
        this.stages[index].tests = tests.concat(this.tests.map((t) => t.id));
        this.save(newTests);
      }
    },

    removeTest(id) {
      let index = this.stages[0].tests.findIndex((t) => t.id === id);
      if (index > -1) {
        this.stages[0].tests.splice(index, 1);
        this.save(this.stages[0].tests, true);
      }
    },

    openSelectTest(index) {
      this.SelectTestIndex = index;
      this.dialogSelectTest = true;
    },
    closeSelectTest() {
      this.dialogSelectTest = false;
    },
    async save(tests, del) {
      try {
        try {
          this.stages = this.stages.map((stage) => {
            delete stage.menu;
            delete stage.menu1;
            return {
              ...stage,
              participants: stage.participants.map((p) => {
                return p.id;
              }),
            };
          });

          this.stages = this.stages.map((s) => {
            s.tests = s.tests.map((t) => {
              if (typeof t.id !== "undefined") {
                return t.id;
              }
              return t;
            });

            return s;
          });

          const { data } = await this.$apollo.mutate({
            mutation: UPDATE_STAGES_MUTATION,
            variables: {
              vacancyId: this.vacancyId,
              stages: this.stages,
              tests: "tests",
            },
          });

          this.$emit("done", data.updateStages.stages, tests, del);
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
